.blog-preview {
  background: #fff;
  border-radius: 15px;
  max-width: 260px;
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;

  @include mq($from: 360px) {
    max-width: 280px;
  }

  @include mq($from: desktop) {
    max-width: 380px;
  }

  &__content {
    padding: 22px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    @include mq($from: desktop) {
      padding: 33px 30px;
    }
  }

  &__descr {
    max-height: 135px;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 87px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 56.48%, #FFFFFF 94.44%);
    }

    @include mq($from: desktop) {
      max-height: 195px;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }

  &__text {
    font-size: 16px;
    line-height: 28px;
    margin-top: 19px;

    @include mq($from: desktop) {
      margin-top: 23px;
    }
  }

  &__media {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    display: block;
  }

  &__image {
    width: auto;
    position: absolute;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__button {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    font-weight: 700;
    color: $orange-color;
    margin-top: 18px;
  }
}
