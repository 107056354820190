.lesson {
  $root: &;
  background: #fff;
  padding: 1px 22px 58px;
  position: relative;

  @include mq($from: desktop) {
    padding: 1px 83px 79px 80px;
    border-radius: 14px;
  }

  &_navy {
    #{$root}__title,
    #{$root}__item-text {
      color: $dark-blue-color;
    }
  }

  &_blue {
    #{$root}__title,
    #{$root}__item-text {
      color: $bright-blue-color;
    }
  }

  &_violet {
    #{$root}__title,
    #{$root}__item-text {
      color: $violet-color;
    }
  }

  &_green {
    #{$root}__title,
    #{$root}__item-text {
      color: #45C480;
    }
  }

  &_yellow {
    #{$root}__title,
    #{$root}__item-text {
      color: #EEA312;
    }
  }

  & + & {
    margin-top: 57px;

    @include mq($from: desktop) {
      margin-top: 80px;
    }
  }

  &__image {
    display: block;
    width: 100%;
    transition: transform .5s, filter .5s;
  }

  &__image-wrapper {
    display: none;

    @include mq($from: 1200px) {
      display: block;
      position: absolute;
      left: 47px;
      width: 505px;
      top: 210px;
      transform: translateX(-100%);

      &_jet-1 #{$root}__image {
        transform: translate3d(100px, 13px, 0px);
        will-change: transform;
      }

      &_jet-2 {
        width: 320px;
        top: auto;
        bottom: 702px;
        left: 0;
      }

      &_jet-3 {
        width: 300px;
        top: 1110px;
        left: auto;
        right: -21px;
        transform: translateX(100%);
      }

      &_calculator-1 {
        width: 405px;
        left: -285px;
        top: -85px;
        transform: rotate(15deg);

        #{$root}__image {
          transform: translate3d(30px, 196px, 0px) scale3d(1.3, 1.3, 1) rotateZ(15deg);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }

      &_calculator-2 {
        width: 314px;
        top: auto;
        bottom: 885px;
        left: -198px;
        transform: rotate(25deg);

        #{$root}__image {
          transform: translate3d(48px, -30px, 0px) scale3d(1.3, 1.3, 1);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }

      &_calculator-3 {
        width: 345px;
        top: 864px;
        left: auto;
        right: -232px;
        transform: none;

        #{$root}__image {
          transform: translate3d(-13px, 83px, 0px) scale3d(1.3, 1.3, 1);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }

      &_pen-1 {
        width: 304px;
        left: 355px;
        top: -15px;
        left: 139px;

        #{$root}__image {
          transform: translate3d(-30px, -100px, 0px) scale3d(1.3, 1.3, 1) rotateZ(-26deg);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }

      &_pen-2 {
        width: 205px;
        top: auto;
        bottom: 696px;
        left: 110px;
        transform: rotate(-17deg) translateX(-100%);

        #{$root}__image {
          transform: translate3d(-30px, -100px, 0px) scale3d(1.3, 1.3, 1);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }

      &_pen-3 {
        width: 257px;
        top: 860px;
        left: auto;
        right: 140px;
        transform: translateX(100%);

        #{$root}__image {
          transform: translate3d(-57px, 196px, 0px) scale3d(1.5, 1.5, 1);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }

      &_book-1 {
        width: 304px;
        left: 355px;
        top: -15px;
        left: 139px;

        #{$root}__image {
          transform: translate3d(-30px, -100px, 0px) scale3d(1.3, 1.3, 1) rotateZ(-26deg);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }

      &_book-2 {
        width: 205px;
        top: auto;
        bottom: 696px;
        left: 110px;
        transform: rotate(-17deg) translateX(-100%);

        #{$root}__image {
          transform: translate3d(-30px, -100px, 0px) scale3d(1.3, 1.3, 1);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }

      &_book-3 {
        width: 257px;
        top: 860px;
        left: auto;
        right: 140px;
        transform: translateX(100%);

        #{$root}__image {
          transform: translate3d(-57px, 196px, 0px) scale3d(1.5, 1.5, 1);
          filter: blur(8px);
          will-change: transform, filter;
        }
      }
    }

  }

  &__title {
    font-size: 25px;
    text-transform: uppercase;
    line-height: 40px;
    font-weight: 900;
    margin-bottom: 37px;

    @include mq($from: 375px) {
      font-size: 38px;
      word-break: break-word;
      text-transform: inherit;
    }

    @include mq($from: 465px) {
      font-size: 48px;
      font-weight: 900;
      line-height: 50px;
    }

    br {
      display: none;
    }

    @include mq($from: desktop) {
      margin-bottom: 50px;

      br {
        display: inline;
      }
    }
  }

  &__list {
    list-style: none;
    margin: 17px 0 28px;
    padding: 0 0 0 22px;

    &-item {
      padding-left: 28px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 13px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $orange-color;
      }

      #{$root}__text {
        margin: 8px 0;
      }
    }
  }

  &__archivements {
    border-radius: 15px;
    padding: 30px 12px 19px;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    background: $light-blue-color;

    @include mq($from: 477px) {
      padding: 30px 12px;
    }

    @include mq($from: desktop) {
      padding: 29px 50px 35px;
      margin-top: 51px;
    }
  }

  &__icon {
    &_smile {
      height: 104px;
    }

    @include mq($until: 1024px) {
      width: auto;
      height: 55px;

      &_light {
        width: 71px;
        height: 55px;
      }

      &_smile {
        width: 69px;
        height: 46px;
      }

      &_letters {
        width: 58px;
        height: 55px;
      }

      &_brain {
        width: 59px;
        height: 55px;
      }

      &_lamp {
        width: 81px;
        height: 52px;
      }

      &_thoughts {
        width: 64px;
        height: 55px;
      }

      &_pen {
        width: 50px;
        height: 58px;
      }

      &_marks {
        width: 64px;
        height: 62px;
      }

      &_hand {
        width: 40px;
        height: 48px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & + & {
      margin-left: 7px;

      @include mq($from: desktop) {
        margin-left: 0;
      }
    }

    @include mq($from: desktop) {
      padding: 0 20px;
      width: auto;
      min-width: 33.3%;
      box-sizing: border-box;
      opacity: 0;
      transform: translateY(50px);
      transition: opacity .5s ease-out, transform .5s ease-out;

      #{$root}__archivements_visible & {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &:nth-child(2) {
      transition-delay: .3s;
    }

    &:nth-child(3) {
      transition-delay: .6s;
    }
  }

  &__item-text {
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
    min-height: 54px;

    @include mq($from: 477px) {
      min-height: none;
    }

    @include mq($from: desktop) {
      font-size: 18px;
      line-height: 25px;
      margin-top: 26px;
    }
  }

  &__item-span {
    text-transform: uppercase;
    font-weight: 900;
  }

  &__test {
    margin-top: 33px;

    @include mq($from: desktop) {
      margin-top: 9px;
    }
  }

  &__content {
    margin-top: 40px;

    @include mq($from: desktop) {
      max-width: 700px;
      margin: 70px auto 0;
    }

    > h3 {
      font-size: 30px;
      line-height: 40px;
      font-weight: 700;
      margin: 35px 0 20px;

      @include mq($from: 375px) {
        font-size: 32px;
        line-height: 45px;
      }
    }

    > p {
      line-height: 30px;

      & + & {
        margin-top: 35px;
      }
    }

    > ul {
      list-style: none;
      margin: 17px 0 28px;
      padding: 0 0 0 22px;

      > li {
        padding-left: 28px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 13px;
          left: 0;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: $orange-color;
        }

        > p {
          margin: 8px 0;
        }
      }
    }

    &-title {
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 20px;
      margin-top: 50px;
    }
  }

  &__free {
    margin-top: 33px;

    @include mq($from: desktop) {
      margin-top: 24px;
    }
  }

  .banner {
    @include mq($from: tablet) {
      &-mobile {
        display: none;
      }
    }

    @include mq($until: tablet) {
      &-desktop {
        display: none;
      }
    }
  }
}
