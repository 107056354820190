.read-city {
  position: fixed;
  bottom: -100%;
  width: auto;
  height: 125px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  z-index: 5;
  box-shadow: 0px 2px 16px 0px #11307F33;
  overflow: hidden;

  transition: bottom 0.5s;


  @include mq($from: 431px) {
    position: absolute;
    left: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 14px;
    width: 132px;
    height: 220px;
    overflow: visible;
    transition: left .3s;
    box-shadow: 0px -8px 30px rgba(0, 25, 90, .08);
  }

  &__back {
    background-color: $light-blue-color;
  }

  &_fixed {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
  }

  &__left-shift {
    left: -132px;
  }

  &_skip {
    position: relative;
    border-radius: 0;
    margin-bottom: 20px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq($from: 431px) {
      display: none;
    }
  }

  &__title {
    font-family: $circle;
    color: $almost-black-color;
    font-size: 20px;
    line-height: 1.24;
    color: #fff;
    display: none;

    &-online {
      color: $orange-color;
    }

    &-caveat {
      font-family: Caveat;
      font-size: 23px;
      color: #fff;
    }

    &_mobile {
      display: block;
      font-size: 28px;
      line-height: 1;
      position: relative;

      &-caveat {
        position: absolute;
        top: 25px;
        font-family: Caveat;
        transform: rotate(-10deg);

        span {
          margin-left: 20px;
        }
      }
    }

    @include mq($from: 431px) {
      display: block;
      line-height: 1.2;

      &_mobile {
        display: none;
      }
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  &__more {
    font-family: Circe;
    color: #FF7435;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.86;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    margin-top: 18px;

    @include mq($from: 431px) {
      margin-top: 15px;
      font-size: 12px;
      line-height: 2.17;
      letter-spacing: 0.01em;
    }
  }

  &__earth {
    position: absolute;
    bottom: -14px;
    right: -3px;
    transform: rotate(6.4deg);

    @include mq($from: 431px) {
      transform: rotate(0deg);
      bottom: 0px;
      right: 0px;
    }
  }

  &__star_2 {
    position: absolute;
    top: 40px;
    right: 105px;
    z-index: 1;

    @include mq($from: 431px) {
      top: 112px;
      left: 23px;
      transform: rotate(30deg);
    }
  }

  &__star_1 {
    position: absolute;
    top: 60px;
    right: 110px;
    z-index: 1;
    transform: rotate(-15deg);

    @include mq($from: 431px) {
      top: 137px;
      left: 3px;
      transform: rotate(45deg);
    }
  }

  &__logo {
    margin-top: 18px;
    margin-bottom: 0;

    @include mq($from: 431px) {
      margin-bottom: 15px;
    }
  }

  &__container {
    padding: 20px 20px;

    @include mq($from: 431px) {
      padding: 10px 17px 97px;
    }
  }

  &__collapse {
    position: absolute;
    bottom: 0;
    right: -28px;
    width: 28px;
    height: 25px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
    box-shadow: 0px -8px 30px rgba(0, 25, 90, .08);
  }

  &__collapse-icons {
    transition: all .3s;
  }

  &__circle {
    position: absolute;
    background: #fff;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    bottom: -35px;
    right: -15px;

    @include mq($from: 431px) {
      width: 124px;
      height: 124px;
      bottom: 0;
      left: 23px;
    }
  }

  &__fox {
    width: auto;
    height: 100%;
    right: 0;
    bottom: 0;
    display: none;

    &_mobile {
      display: block;
    }

    @include mq($from: 431px) {
      width: 100%;
      display: block;
      right: 0;
      bottom: 0;
    }
  }
}
