.franchise-banner {
  text-decoration: none;
  display: block;

  position: relative;
  aspect-ratio: 1;
  background-color: white;

  background-image: url('/images/franchise-banner-photo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  @include mq($from: tablet) {
    display: none;
  }


  &__text {
    position: relative;
    top: calc(100%/12);
    right: 10%;
    width: 100%;

    h2 {
      font-family: 'Dela Gothic One', 'Circle';
      font-weight: 400;
      font-size: calc(21cqw/3);
      line-height: 110%;
      text-align: right;
      text-transform: uppercase;

      color: #FF541F;
    }

    p {
      margin: 10px 0 0 0;
      font-weight: 600;
      font-size: calc(16cqw/3);
      line-height: 125%;
      text-align: right;
      color: #1E1E1E;
    }
  }

  &__button {
    --width: calc(180cqw/3);
    --height: calc(45cqw/3);

    position: absolute;
    bottom: 20px;
    left: calc(50% - var(--width) / 2);

    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--width);
    height: var(--height);

    transform: scale();

    border-radius: calc(30cqw/3);

    background: linear-gradient(180deg, #FF9F44 0%, #FE7C04 100%);

    font-weight: 700;
    font-size: calc(14cqw/3);
    line-height: 100%;

    color: white;
  }

  &__star {
    --size: calc(40%/3);
    width: var(--size);
    height: var(--size);

    position: absolute;

    left: calc(15%/3);
    top: calc(80%/3);
  }
}