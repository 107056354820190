.methods {
  padding: 35px 0 0;
  overflow: hidden;

  @include mq($from: desktop) {
    padding: 60px 0 0;
  }

  &__holder {
    @include holder;
  }

  &__list {
    margin-top: 15px;

    @include mq($from: desktop) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 64px;
    }
  }

  &__content {
    @include mq($from: desktop) {
      max-width: 675px;
    }
  }

  &__sidebar {
    padding: 50px 20px 0;
    box-sizing: border-box;
    border-radius: 10px;
    background: $light-blue-color;
    margin-top: 68px;
    position: relative;

    @include mq($from: desktop) {
      width: 380px;
      margin-left: 20px;
      padding: 50px 56px 0;
      flex-shrink: 0;
      margin-top: 5px;
    }
  }

  &__year {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
  }

  &__item {
    & + & {
      margin-top: 32px;

      @include mq($from: desktop) {
        margin-top: 53px;
      }
    }
  }

  &__box {
    @include mq($from: desktop) {
      transform: translate3d(0px, -70px, 0px);
      opacity: 0;
    }
  }

  &__item-text {
    font-size: 22px;
    line-height: 34px;
    margin-top: 3px;
  }

  &__text {
    font-size: 18px;
    line-height: 32px;
    margin: 0;

    & + & {
      margin-top: 32px;
    }
  }

  &__quote {
    font-size: 22px;
    line-height: 38px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -33px;
      left: 0;
      width: 154px;
      height: 111px;
      background: svg-load('quote.svg', fill="#E9EDF6") center no-repeat;
      z-index: -1;
    }
  }

  &__button {
    display: block;
    margin-top: 26px;

    @include mq($from: desktop) {
      opacity: 0;
      transform: translate3d(0px, -70px, 0px);
      transition: transform .3s ease-out;

      &_visible {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px);
      }
    }
  }

  &__medal {
    display: block;
    position: absolute;
    right: -12px;
    top: -52px;
    width: 134px;

    @include mq($from: desktop) {
      right: -48px;
      top: -63px;
      width: 168px;
    }
  }

  &__join {
    margin-top: 56px;

    @include mq($from: desktop) {
      margin-top: 126px;
    }

    &-title {
      font-size: 32px;
      line-height: 42px;
      font-weight: 700;
      margin-bottom: 23px;
      word-break: break-word;
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    margin: 0 auto;

    @include mq($from: 1100px) {
      margin: 0 50px;
      max-width: calc(100% - 100px);
    }
  }

  &__fox {
    display: block;
    margin: 27px auto 0;

    @include mq($from: desktop) {
      margin: 36px auto 0;
    }
  }

  &__bottom {
    display: none;

    @include mq($from: desktop) {
      margin-top: 57px;
      display: block;
    }

    &_mobile {
      display: block;
      margin-top: 86px;

      @include mq($from: desktop) {
        display: none;
      }
    }
  }

  &__founders {
    font-size: 16px;
    line-height: 26px;
    color: rgba($almost-black-color, .4);
  }

  &__image-wrapper {
    position: relative;
    max-width: 320px;
    margin: 10px auto 0;

    @include mq($from: desktop) {
      max-width: unset;
      margin-top: 21px;
    }
  }

  &__names {
    display: flex;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;

    @include mq($from: desktop) {
      position: absolute;
      top: -5px;
      margin: 0;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: rgba($almost-black-color, .4);
  }
}
