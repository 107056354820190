.lesson-review {
  $root: &;

  &_navy {
    #{$root}__image {
      @include mq($from: desktop, $until: 1026px) {
        bottom: -55px;
      }
    }
  }

  &_blue {
    #{$root}__card {
      background: $bright-blue-color;
    }
  }

  &_violet {
    #{$root}__card {
      background: $violet-color;
    }

    #{$root}__circle {
      @include mq($from: desktop) {
        left: 10px;
      }

      @include mq($from: 1170px) {
        left: -20px;
      }
    }
  }

  &_green {
    #{$root}__card {
      background: #45C480;
    }

    @include mq($from: desktop) {
      #{$root}__image {
        margin-left: -30px;
      }
    }

  }

  &_yellow {
    #{$root}__card {
      background: #EEA312;
    }

    @include mq($from: desktop) {
      #{$root}__image {
        margin-left: -30px;
      }
    }

  }

  &__name {
    font-size: 14px;
    line-height: 20px;
    color: rgba(57, 63, 97, .5);

    @include mq($from: desktop) {
      text-align: right;
    }
  }

  &__card {
    margin-top: 9px;
    background: $dark-blue-color;
    box-shadow: 0 20px 35px rgba(0, 15, 55, .32);
    border-radius: 10px;
    padding: 45px 20px 40px;

    @include mq($from: desktop) {
      padding: 20px 50px 0;
      overflow: hidden;
    }

    @include mq($from: 1170px) {
      padding: 20px 60px 0 111px;
    }
  }

  &__items {
    @include mq($from: desktop) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;

    @include mq($from: desktop) {
      max-width: 472px;
      padding: 34px 0 52px;
      flex-direction: column;
      justify-content: center;
    }

    &-text {
      color: white;
    }
  }

  &__quote {
    font-style: italic;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: #fff;
    display: none;

    &_mobile {
      display: block;
      margin-bottom: 19px;
    }

    @include mq($from: desktop) {
      display: block;

      &_mobile {
        display: none;
      }
    }
  }

  &__results {
    margin-top: 35px;
    display: flex;
    justify-content: center;

    @include mq($from: desktop) {
      margin: 40px -24px 0;
      display: block;

      &_images {
        margin: 40px 0 0;
      }
    }
  }

  &__list {
    font-size: 16px;
    color: rgba(#fff, .5);
    width: 100%;
    max-width: 280px;

    @include mq($from: desktop) {
      font-size: 18px;
      display: flex;
      max-width: none;
    }
  }

  &__col {
    display: flex;
    margin: 34px 0;

    @include mq($from: desktop) {
      flex-direction: column;
      justify-content: space-between;
      padding: 0 24px;
      margin: 0;
    }
  }

  &__item {
    width: 50%;

    @include mq($from: desktop) {
      width: auto;
    }

    & + & {
      margin-left: 35px;

      @include mq($from: desktop) {
        margin: 27px 0 0;
      }
    }

    &_after {
      color: #fff;

      #{$root}__text {
        position: relative;
      }

      #{$root}__text:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -9px;
        transform: translate(-100%, -50%);
        width: 10px;
        height: 13px;
        background: svg-load('arrow-up.svg', fill="#0FBA20") center no-repeat;
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: 26px;
    margin-top: 5px;
    font-weight: 700;

    @include mq($from: desktop) {
      font-size: 18px;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 26px;
  }

  &__button {
    display: block;

    @include mq($from: desktop) {
      margin-top: 65px;

      #{$root}__results_images + & {
        margin-top: 40px;
      }
    }
  }

  &__image-wrapper {
    position: relative;
    display: flex;

    @include mq($from: desktop) {
      min-width: 298px;
    }
  }

  &__image {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0 auto;
    max-height: 325px;
    object-fit: contain;
    object-position: bottom;

    @include mq($from: desktop) {
      max-height: none;
      transform: translateY(20px);
      transition: transform .5s ease-in-out;

      #{$root}_visible & {
        transform: translateY(0);
      }
    }
  }

  &__circle {
    width: 240px;
    height: 234px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    #{$root}_visible & {
      transform: translate(0, -50%) scaleX(1) scaleY(1);
      opacity: 1;
    }

    @include mq($from: 375px) {
      width: 280px;
      height: 274px;
    }

    @include mq($from: desktop) {
      left: -13px;
      transform: translate(0, -50%) scaleX(0.01) scaleY(0.01);
      opacity: 0;
      transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
    }

    @include mq($from: 1170px) {
      width: 359px;
      height: 350px;
      left: -53px;
    }
  }
}
