.menu {
  opacity: 0;
  height: 0;
  background: #fff;
  overflow-x: auto;
  z-index: 6;
  width: 100%;

  @include mq($from: desktop) {
    transition: opacity .2s;
    overflow-x: hidden;
  }

  &_visible {
    display: flex;
    justify-content: space-between;
    opacity: 1;
    height: auto;
    position: fixed;
    box-shadow: 0 4px 25px rgba(0, 12, 21, .1);
  }

  &__holder {
    @include holder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;

    @include mq($from: desktop) {
      padding: 0 20px;
    }
  }

  &__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 20px;

    @include mq($from: desktop) {
      overflow-x: hidden;
      padding: 0;
    }
  }

  &__item {
    text-transform: uppercase;
    box-sizing: border-box;
    min-height: 51px;
    font-weight: 700;
    display: flex;
    align-items: center;
    border-bottom: 3px solid transparent;
    color: rgba(57, 63, 97, .4);
    transition: color .2s ease-out, border-color .2s ease-out;
    white-space: nowrap;
    font-size: 20px;

    & + & {
      margin-left: 55px;
    }

    &_active {
      border-bottom: 3px solid $orange-color;
      color: $orange-color;
    }

    @include mq($from: desktop) {
      white-space: normal;
      font-size: 18px;
    }
  }

  &__link {
    text-decoration: none;
    font-size: 13px;
    color: inherit;

    &:hover {
      color: $orange-color;
    }
  }

  &__button {
    display: none;

    @include mq($from: desktop) {
      display: block;
      flex-shrink: 0;
    }
  }
}
