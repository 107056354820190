.review {
  $root: &;
  padding: 34px 40px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 21, 52, .1);
  border-radius: 10px;

  & + & {
    margin-top: 30px;
  }

  &_full {
    padding: 20px 0;
    box-shadow: none;

    @include mq($from: desktop) {
      padding: 0;
    }

    #{$root}__text {
      max-height: unset;

      &:after {
        display: none;
      }
    }

    #{$root}__button {
      display: none;
    }
  }

  &__line {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }

  &__date {
    font-size: 16px;
    line-height: 26px;
    color: rgba(#000, .4);
  }

  &__text {
    margin-top: 23px;
    font-size: 18px;
    line-height: 32px;
    position: relative;
    max-height: 90px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(#C4C4C4, 0) 65.56%, rgba(255, 255, 255, .65) 100%);
    }
  }

  &__button {
    margin-top: 32px;
    display: inline-block;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 1px;
    font-weight: 700;
    color: $orange-color;
    text-decoration: none;
    text-transform: uppercase;

    @include mq($from: desktop) {
      margin-top: 20px;
    }
  }
}
