.mountain {
  background: $bright-blue-color;
  padding: 36px 0 0;
  color: #fff;
  overflow: hidden;

  @include mq($from: desktop) {
    padding: 106px 0 186px;
    margin-top: -47px;
  }

  &__holder {
    @include holder;
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 1;

    @include mq($from: desktop) {
      max-width: 580px;
    }
  }

  &__descr {
    margin-top: 32px;

    @include mq($from: desktop) {
      margin-top: 49px;
    }
  }

  &__video.video {
    max-width: 700px;
    margin: 44px auto 0;

    @include mq($from: desktop) {
      max-width: 680px;
      margin: 67px 0 0;
    }
  }

  &__clouds-group {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform .3s ease;
  }

  &__image {
    margin: 80px 0 -91px;
    width: 420px;
    display: block;
    height: 312px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    transition: transform .3s ease;
    z-index: 1;

    @include mq($from: desktop) {
      width: 1000px;
      height: 739px;
      position: absolute;
      bottom: -187px;
      left: calc(100% - 20px);
      transform: translate3d(-50%, 222px, 0px);
      margin: 0;
      z-index: 0;
    }
  }

  &__cloud {
    width: 212px;
    height: 91px;
    position: absolute;
    left: 101px;
    bottom: 228px;
    transform: translate(-100%, 0);
    display: none;

    @include mq($from: desktop) {
      width: 286px;
      height: 123px;
      bottom: 36px;
      left: -15px;
      display: block;
    }

    &_1,
    &_6,
    &_4 {
      display: block;
    }

    &_2 {
      width: 316px;
      height: 136px;
      bottom: auto;
      top: 144px;
      left: -173px;
    }

    &_3 {
      width: 380px;
      height: 164px;
      bottom: -88px;
      left: 583px;
      transform: translate(0, 0);
    }

    &_4 {
      width: 125px;
      height: 54px;
      right: -43px;
      left: auto;
      bottom: 182px;
      transform: translate(0, 0);

      @include mq($from: desktop) {
        width: 146px;
        height: 63px;
        bottom: 255px;
        left: 760px;
        transform: translate(100%, 0);
      }
    }

    &_5 {
      width: 180px;
      height: 80px;
      top: 122px;
      left: auto;
      right: 214px;
      transform: translate(0, 0);
    }

    &_6 {
      width: 288px;
      height: 124px;
      top: 239px;
      bottom: auto;
      left: auto;
      right: 95px;
      transform: translate(100%, 0);

      @include mq($from: desktop) {
        width: 445px;
        height: 192px;
        top: -68px;
        left: auto;
        right: 69px;
        transform: translate(100%, 0);
      }
    }

    &_7 {
      width: 125px;
      height: 54px;
      left: auto;
      right: -44px;
      bottom: 141px;
      transform: translate(100%, 0);
    }

    &_8 {
      width: 476px;
      height: 195px;
      bottom: -186px;
      top: auto;
      left: auto;
      right: -13.59%;
      transform: translate(100%, 0);
    }

    &_9 {
      width: 240px;
      height: 103px;
      bottom: 405px;
      left: auto;
      right: -114px;
      transform: translate(100%, 0);
    }
  }
}
