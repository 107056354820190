.program-index-cards {
  &__holder {
    @include holder;
    max-width: 1180px;

    padding: 24px 22px 32px 22px;

    @include mq($from: tablet) {
      padding: 35px 35px 40px 35px;
    }

    @include mq($from: desktop) {
      position: relative;
      background: white;
      border-radius: 15px;

      top: -147px;

      padding: 50px 50px 60px 50px;
    }
  }

  &__bg {
    @include mq($until: desktop) {
      display: none;
    }

    width: 100%;
    height: 147px;
    background: #25ADE9;
  }

  &__content {
    position: relative;
  }

  &__items {
    display: flex;
    gap: 16px;

    flex-direction: column;

    @include mq($from: tablet) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include mq($from: desktop) {
      gap: 21px;
    }
  }

  &__all {
    display: block;
    margin-top: 32px;

    @include mq($from: tablet) {
      width: max-content;
      margin-top: 40px;
    }

    @include mq($from: desktop) {
      margin-top: 60px;
    }
  }
}
