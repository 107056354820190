.reviews {
  padding: 37px 0 23px;
  background: $light-blue-color;
  overflow: hidden;

  @include mq($from: desktop) {
    overflow: unset;
    padding: 50px 0 83px;
  }

  &__title {
    @include mq($from: desktop) {
      text-align: center;
    }
  }

  &__holder {
    @include holder;
  }

  &__items {
    margin-top: 30px;

    @include mq($from: desktop) {
      display: flex;
      align-items: flex-start;
      margin-top: 70px;
      position: relative;
    }
  }

  &__slider {
    overflow: visible;

    @include mq($from: desktop) {
      overflow: hidden;
    }
  }

  &__images {
    display: flex;
    align-items: flex-start;

    @include mq($from: desktop) {
      display: block;
    }
  }

  &__image {
    display: block;
    height: auto;
    max-width: 260px;

    @include mq($from: 360px) {
      max-width: 280px;
    }

    @include mq($from: desktop) {
      max-width: 100%;

      & + & {
        margin-top: 20px;
      }
    }
  }

  &__list {
    margin-top: 40px;

    @include mq($from: desktop) {
      margin: 0 0 0 62px;
      flex-shrink: 0;
      max-width: 580px;

      &.sticky {
        top: 150px;
      }
    }
  }

  &__links {
    margin-top: 30px;

    @include mq($from: desktop) {
      margin-top: 60px;
      display: flex;
      align-items: center;
    }
  }

  &__button {
    display: block;
  }

  &__link {
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    color: $orange-color;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 20px;
    display: block;
    text-align: center;

    @include mq($from: desktop) {
      margin: 0 0 0 40px;
    }
  }
}
