.mobile_free_lesson {
    @include mq($from: tablet) {
        display: none;
    }

    padding: 0 20px 20px 20px;
    background: #25ADE9;

    &__holder {
        padding: 20px;
        border-radius: 30px;
        background: #fff;

        display: grid;
        gap: 20px;
    }

    &__keyword {
        text-decoration: none;
        color: $orange-color;
    }

    &__buttons {
        display: inline-grid;
        grid-template-columns: 4fr 7fr;
        gap: 20px;

        .button {
            text-wrap-mode: nowrap;
        }
    }

    h1 {
        font-size: 30px;
        line-height: 36px;
    }

    &__description {
        line-height: 25.2px;
    }

    h3 {
        font-size: 18px;
        line-height: 25.2px;
    }
}