.speed {
  $root: &;
  padding: 80px 0 0 ;
  background: $light-blue-color;

  @include mq($from: desktop) {
    padding: 119px 0 57px;
  }

  &__holder {
    @include holder;

    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__card {
    padding: 38px 20px 0;
    background: #fff;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 9px 40px -1px transparent;
    transition: box-shadow .2s, color .2s;

    & + & {
      margin-top: 30px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 686px;
      border-radius: 50%;
      height: 686px;
      bottom: 199px;
      left: auto;
      right: 0;
      background: $orange-color;
      transition: transform .35s ease;
      transform: translate(50%, 100%) scale3d(1, 1, 1);
      transform-style: preserve-3d;
    }

    &:hover {
      box-shadow: 0 9px 40px -1px rgba(241, 136, 37, .44);
      color: #fff;

      &:after {
        transform: translate(50%, 100%) scale3d(2.6, 2.6, 1);
      }

      #{$root}__button {
        border-color: transparent;
        color: $orange-color;
      }
    }

    &_blue {
      &:after {
        background: #5282BD;
      }

      &:hover {
        box-shadow: 0 9px 40px -1px rgba(82, 130, 188, .44);
      }

      &:hover #{$root}__button {
        color: #5282BD;
      }
    }

    @include mq($from: 767px) {
      padding: 38px 20px;

      &:hover:after {
        transform: translate(50%, 100%) scale3d(3.2, 3.2, 1);
      }
    }

    @include mq($from: desktop) {
      padding: 38px 40px 60px;
      width: 50%;

      & + & {
        margin: 0 0 0 20px;
      }

      &:hover:after {
        transform: translate(50%, 100%) scale3d(2.3, 2.3, 1);
      }
    }
  }

  &__content {
    max-width: 467px;
    position: relative;
    z-index: 1;
  }

  &__title {
    font-size: 32px;
    line-height: 47px;
    font-weight: 700;
  }

  &__text {
    font-size: 20px;
    line-height: 32px;
    margin: 8px 0 0;

    @include mq($from: desktop) {
      margin: 17px 0 0;
    }
  }

  &__image {
    display: block;
    position: relative;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 26px 0 0 auto;

    @include mq($from: 767px) {
      position: absolute;
      bottom: 0;
      right: -20px;
      margin: 0;
    }
  }

  &__button {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    margin: 24px 0 0;
    background: #fff;

    &:hover {
      box-shadow: none;
      background: #fff;
    }

    @include mq($from: 767px) {
      min-width: 200px;
      width: auto;
    }

    @include mq($from: desktop) {
      margin-top: 44px;
    }
  }
}
