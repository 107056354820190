.video {
  $root: &;
  max-width: 1024px;
  margin: 48px auto 0;

  @include mq($from: tablet) {
    margin-top: 52px;
  }

  @include mq($from: desktop) {
    margin-top: 68px;
    cursor: pointer;
  }

  &_simple {
    margin: 0;
    max-width: 710px;

    #{$root}__play {
      width: 53px;
      height: 53px;
    }
  }

  &__title {
    margin-top: 23px;
    font-size: 16px;
    line-height: 24px;
    color: rgba($almost-black-color, .5);

    @include mq($from: desktop) {
      margin-top: 15px;
      text-align: center;
    }
  }

  &__holder {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
  }

  &__play {
    position: absolute;
    background-color: $orange-color;
    background-image: svg-load('play.svg', fill="#fff");
    background-size: contain;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 84px;
    height: 60px;
    z-index: 2;
    cursor: pointer;
    display: block;
    backface-visibility: hidden;
    border-radius: 16px;
    box-shadow: 0 14px 25px rgba(255, 107, 0, .76);
    transition: all .2s;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      box-shadow: 0 4px 0 #FFCEB2;
      border-radius: 16px;
      margin-top: -4px;
      position: absolute;
    }

    &:hover,
    &:active {
      background-color: #EA5908;
      background-image: svg-load('play.svg', fill="#fff");
      background-size: contain;
      box-shadow: 0 9px 10px rgba(255, 107, 0, .76);
    }
  }

  &__overflow {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: linear-gradient(0deg, rgba(96, 113, 130, .5), rgba(96, 113, 130, .5));
  }

  &__poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
    backface-visibility: hidden;
    transform: translateZ(0);
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &_playing {
    #{$root}__play,
    #{$root}__poster,
    #{$root}__overflow {
      display: none;
    }
  }

  &__preloader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}
