// base color vars
$orange-color: #F18825;

$almost-black-color:  #393F61;

$blue-color: #42BCF2;

//cards colors
$dark-blue-color: #3381DC;
$bright-blue-color: #4EB8E7;
$violet-color: #5C6297;

//form fields colors
$light-gray-color: #F2F2F2;
$gray-color: #B4B4B4;

//form active color
$active-blue-color: #3BA1CE;

//background
$light-blue-color: #EDF6FF;
$navy-color: #053057;
$gray-blue-color: #DCE9F5;

// typography vars
$base-font-size: 18px;
$circle: 'Circe', 'Arial', 'Helvetica', sans-serif;
$arial: 'Arial', 'Helvetica', sans-serif;

// media queries vars
$mq-breakpoints: (
  mobile:  320px,
  tablet: 768px,
  desktop:  1024px,
  wide:    1280px,

  desktopAd: 810px,
  mobileLandscape: 480px
);
