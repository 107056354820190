.program-index-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 14px;

  @include mq($from: tablet) {
    flex-basis: calc(50% - 21px / 2);
    min-width: 100px;
  }

  @include mq($until: 1000px) {
    flex-basis: 100%;
  }

  @include mq($from: desktop) {
    padding: 20px 20px 28px;
  }

  &__title {
    color: white;
    font-size: 19px;
    font-weight: 800;
    line-height: 1;
    z-index: 4;

    max-width: 65%;

    @include mq($from: 375px) {
      font-size: 24px;
    }

    @include mq($from: 410px) {
      font-size: 28px;
    }

    @include mq($from: desktop) {
      font-size: 32px;
    }

    >* {
      margin: 0;
      display: inline-flex;
    }

    &.dark {
      color: #2D3453;
    }
  }

  &__short-description,
  &__detail-link {
    color: white;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.1;
    z-index: 4;

    @include mq($from: desktop) {
      font-size: 20px;
    }

    &.dark {
      color: #2D3453;
    }
  }

  &__short-description {
    max-width: 54%;

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__detail-link {
    padding-top: 90px;
    margin-top: auto;
    opacity: .7;

    display: flex;
    align-items: center;
    gap: 6px;

    @include mq($until: tablet) {
      padding-top: 50px;
    }

    &::after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNSIgeT0iNS41IiB3aWR0aD0iMTIiIGhlaWdodD0iMiIgcng9IjEiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xNCAyLjVMMTggNi41TDE0IDEwLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=);
      content: "";
      width: 20px;
      height: 100%;
      background-repeat: no-repeat;
      background-position-y: center;
    }

    &.dark::after {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5H13M13 5L9.16 1M13 5L9.16 9' stroke='%232D3453' stroke-opacity='0.8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    >a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__circle {
    width: 214px;
    height: 214px;

    @include mq($from: desktop) {
      width: 295px;
      height: 295px;
    }

    border-radius: 50%;

    position: absolute;
    bottom: 0;
    right: 0;

    transform: translate(37px, 35px);

    @include mq($from: desktop) {
      transform: translate(46px, 65px);
    }

    background: rgba(255, 255, 255, 0.2);
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    justify-content: end;
    align-items: end;

    width: auto;
    height: 100%;

    display: flex;

    img {
      height: 100%;
    }
  }
}