.blog-cards {
  padding: 23px 0 64px;
  background: $light-blue-color;
  overflow: hidden;

  @include mq($from: desktop) {
    padding: 84px 0 48px;
  }

  &__title {
    @include mq($from: desktop) {
      text-align: center;
    }
  }

  &__holder {
    @include holder;
  }

  &__button {
    display: block;
    margin-top: 30px;

    @include mq($from: desktop) {
      display: block;
      max-width: 247px;
      margin: 80px auto 0;
    }
  }

  &__slider {
    margin-top: 30px;
    overflow: visible;

    @include mq($from: desktop) {
      margin-top: 27px;
    }
  }

  &__list {
    margin-top: 34px;
  }

  &__arrows {
    display: none;

    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__preview {
    @include mq($from: desktop) {
      opacity: .2;
      transition: opacity .2s;

      &.swiper-slide-active,
      &.swiper-slide-next,
      &.swiper-slide-next + & {
        opacity: 1;
      }
    }
  }
}
