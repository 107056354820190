.certificates {
  background: $light-blue-color;
  padding: 40px 0 100px;
  overflow: hidden;

  @include mq($from: desktop) {
    padding: 70px 0 116px;
  }

  &__holder {
    @include holder;
  }

  &__title {
    font-size: 32px;
    line-height: 42px;
  }

  &__content {
    margin-top: 28px;

    @include mq($from: desktop) {
      margin-top: 40px;
      display: flex;
      align-items: flex-start;
    }
  }

  &__slider {
    overflow: visible;
    margin: 25px -5px 0;

    @include mq($from: desktop) {
      margin: -10px 0 0;
    }
  }

  &__list {
    display: flex;

    @include mq($from: desktop) {
      flex-wrap: wrap;
    }
  }

  &__main {
    border: 10px solid #D2DFEC;
    background: #D2DFEC;
    width: 280px;
    height: 380px;
    flex-shrink: 0;
    box-sizing: border-box;
    box-shadow: 0 11px 25px rgba(0, 15, 55, .1);
    margin: auto;
    cursor: pointer;

    @include mq($from: 360px) {
      width: 320px;
      height: 434px;
    }

    @include mq($from: desktop) {
      margin: 0 75px 0 0;
      width: 398px;
      height: 540px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 37px;
        height: 37px;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }

      &:hover:after {
        background: svg-load('zoom-plus.svg', fill=#EF4824) center no-repeat;
      }
    }
  }

  &__item {
    border: 6px solid #D2DFEC;
    background: #D2DFEC;
    width: 90px;
    height: 119px;
    margin: 5px;
    display: flex;
    box-sizing: border-box;
    flex-shrink: 0;
    cursor: pointer;
    opacity: .4;
    transition: opacity .2s;

    &_active {
      opacity: 1;
    }

    @include mq($from: desktop) {
      border: 7px solid #D2DFEC;
      width: 103px;
      height: 136px;
      margin: 15px 7px;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
}
