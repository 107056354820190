.teacher-promo {
  $root: &;

  padding: 0;
  color: #fff;
  overflow: hidden;

  @include mq($from: desktop) {
    padding: 77px 0 0;
    background: $light-blue-color;
  }

  &__content {
    padding: 40px 0 127px;
    background: radial-gradient(148.59px at 65.26% 50%, #62C3ED 0%, #37ACE0 100%);

    @include mq($from: 767px) {
      background: radial-gradient(792.50px at 65.26% 50%, #62C3ED 0%, #37ACE0 100%);
    }

    @include mq($from: desktop) {
      padding: 64px 0 70px;
    }
  }

  &__holder {
    @include holder;
    position: relative;
  }

  &__title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
  }

  &__image {
    display: block;
    position: absolute;
    bottom: -207px;
    right: -50px;
    width: 232px;

    @include mq($from: desktop) {
      bottom: -70px;
      right: 0;
      width: 262px;
      transition: transform .4s ease-out, opacity .4s ease-out;
      transform: translateY(44px);
      opacity: 0;

      #{$root}_visible & {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &__button {
    display: inline-block;
    color: #fff;
    border-color: #fff;
    margin-top: 40px;
    min-width: 176px;
    box-sizing: border-box;

    &-span {
      display: none;

      @include mq($from: desktop) {
        display: inline;
      }
    }

    @include mq($from: desktop) {
      margin-top: 47px;
    }
  }
}
