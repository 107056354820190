.about-page {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  
    &__content {
      background: $light-blue-color;
    }
  
    &__holder {
      @include holder;
      padding: 0;
  
      @include mq($from: desktop) {
        padding: 0 20px;
        position: relative;
      }
    }
  
    &__breadcrumbs {
      padding: 30px 10px 30px;
  
      display: flex;
      flex-wrap: wrap;
  
      > div {
        display: flex;
      }
  
      @include mq($from: desktop) {
        padding: 50px 0 50px;;
      }
    }
  }
  