.book {
  $root: &;
  padding: 35px 0;
  color: #fff;
  background: $bright-blue-color;
  overflow: hidden;

  @include mq($from: desktop) {
    padding: 124px 0 50px;
  }

  &_short {
    overflow: hidden;
    box-sizing: border-box;

    #{$root}__descr,
    #{$root}__notice {
      display: none;
    }

    #{$root}__show-more:after {
      display: block;
    }

    @include mq($from: desktop) {
      max-height: 624px;
    }
  }

  &__title {
    text-align: left;
    margin-bottom: 20px;

    @include mq($from: desktop) {
      margin-bottom: 64px;
    }
  }

  &__text {
    font-size: 15px;
    line-height: 26px;

    @include mq($from: desktop) {
      font-size: 20px;
      line-height: 33px;
    }
  }

  &__button {
    display: block;
    text-align: center;
    margin-top: 10px;
    padding: 16px 14px;
    width: 100%;
    box-sizing: border-box;

    @include mq($from: desktop) {
      margin-top: 40px;
      display: inline-block;
      min-width: 290px;
      width: auto;
    }
  }

  &__holder {
    @include holder;
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mq($from: desktop) {
      max-width: calc(100% - 473px - 20px);
    }

    @include mq($from: 1100px) {
      max-width: 580px;
    }
  }

  &__descr {
    margin-top: 30px;

    @include mq($from: desktop) {
      margin: 52px 0;
    }
  }

  &__image-wrapper {
    margin: 37px auto 0;
    max-width: 280px;

    @include mq($from: desktop) {
      max-width: 473px;
      position: absolute;
      margin: 0;
      top: -25px;
      right: 0;
    }
  }

  &__image {
    display: block;
    transform: translateX(26px);
    max-width: 100%;

    @include mq($from: desktop) {
      transform: none;
    }
  }

  &__video {
    border-radius: 6px;
    overflow: hidden;
    margin-top: 30px;

    @include mq($from: desktop) {
      margin-top: 48px;
    }
  }

  &__notice {
    font-size: 14px;
    line-height: 21px;
    color: rgba(#fff, .5);
    margin-top: 40px;
    display: none;

    @include mq($from: desktop) {
      margin: 12px 0 0 115px;
      font-size: 15px;
      line-height: 22px;
      display: block;
    }

    &_mobile {
      display: block;

      @include mq($from: desktop) {
        display: none;
      }
    }

    &-title {
      font-weight: 700;
    }

    &-text {
      margin-top: 8px;

      @include mq($from: desktop) {
        margin-top: 10px;
      }

      & + & {
        margin-top: 13px;

        @include mq($from: desktop) {
          margin-top: 30px;
        }
      }
    }
  }

  &__show-more {
    font-size: 14px;
    line-height: 21px;
    opacity: .5;
    transition: opacity .2s;
    position: relative;
    padding-left: 21px;
    cursor: pointer;
    margin: 30px auto 0;
    display: inline-block;

    @include mq($from: desktop) {
      margin: 89px 0 0;
      font-size: 17px;
      line-height: 26px;
      display: block;
    }

    &:before {
      content: '';
      width: 10px;
      height: 2px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      content: '';
      width: 2px;
      height: 10px;
      background: #fff;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }

    &:hover {
      opacity: 1;
    }
  }
}
