.lesson-test {
  background: $light-blue-color;
  border-radius: 10px;
  padding: 30px 20px 40px;

  @include mq($from: desktop) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 41px 32px 36px 44px;
  }

  &__text {
    line-height: 30px;
  }

  &__button {
    border: 1px solid $orange-color;
    box-sizing: border-box;
    border-radius: 10px;
    color: $orange-color;
    flex-shrink: 0;
    display: flex;
    text-decoration: none;
    padding: 16px 55px 16px 35px;
    position: relative;
    transition: all .2s;
    margin-top: 19px;

    @include mq($from: desktop) {
      margin: 0 0 0 10px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      width: 22px;
      height: 22px;
      background: svg-load('play-triangle.svg', fill=$orange-color) center no-repeat;
      transform: translateY(-50%);
    }

    &:hover,
    &:active {
      color: #EA5908;
      border-color: #EA5908;
      box-shadow: 0 5px 8px rgba(247, 125, 11, .3);

      &:after {
        background: svg-load('play-triangle.svg', fill=#EA5908) center no-repeat;
      }
    }
  }
}
