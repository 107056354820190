.compare-images {
  border-radius: 10px;

  & + .lesson-review__button {
    margin-top: 41px;
  }

  &__before {
    display: none;
  }
}
