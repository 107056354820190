.slider {
  display: flex;
  justify-content: center;
  background: #25ADE9;
  padding-bottom: 20px;

  .swiper-container {
    max-width: 1180px;
    max-height: 450px;
    aspect-ratio: calc(1180 / 450);
    z-index: 0;
    border-radius: 12px;
    @include mq($until: tablet) {
      aspect-ratio: calc(386 / 513);
      width: 100%;
      max-width: none;
      max-height: none;
    }
  }

  &__pagination {
    .swiper-pagination-bullet {
      background: #D9D9D980 !important;
      @include mq($until: tablet) {
        background: #30385580 !important;
      }

      opacity: 1;

      width: 10px;
      height: 10px;
      margin: 0 8px !important;
    
      &-active {
        background: $orange-color !important;
      }
    }

    display: flex;
    @include mq($until: desktop) {
      display: none;
    }
    @include mq($until: tablet) {
      display: flex;
      top: -60px;
    }

    position: relative;
    top: -100px;
  }

  &__arrows {
    @include mq($until: desktop) {
      display: none;
    }
    
    justify-content: space-between;

    position: relative;
    top: -245px;
  }

  &__arrow {
    width: 38px;
    height: 38px;

    margin-inline: 32px;
  }

  &__arrow::before {
    width: 13.76px;
    height: 16.77px;
  }

  &__image {
    width: 100%;
    @include mq($until: tablet) {
      display: none;
    }
    aspect-ratio: calc(1180 / 450);
  }

  &__image-mobile {
    width: 100%;
    @include mq($from: tablet) {
      display: none;
    }
    aspect-ratio: calc(386 / 513);
  }

  &__btn {
    border-radius: 200px;

    position: relative;
    top: -17%;
    left: 8.5%;
    padding: 18px 76px 14px;

    &::after {
      border-radius: 200px;
    }

    @include mq($until: tablet) {
      margin-inline: auto;
      border-width: 2px;
      top: -90px;

      width: 212px;
      left: calc(50% - 106px);

      padding: 14px 33px 12px;
      font-weight: 700;
    }
  }
}
