.recommendation {
  padding: 4px 0 35px;

  @include mq($from: desktop) {
    padding: 110px 0 60px;
  }

  &__holder {
    @include holder;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 44px -10px 0;

    @include mq($from: desktop) {
      flex-wrap: nowrap;
      margin: 71px -20px 0;
    }
  }

  &__image {
    display: block;
    border-radius: 10px;
    width: 100%;
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    margin-top: 29px;
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    color: rgba($almost-black-color, .4);
  }

  &__tools {
    margin-top: 10px;
    position: relative;
  }

  &__button {
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background: none;
    cursor: pointer;
    color: $orange-color;
  }

  &__item {
    margin-top: 27px;
    padding: 0 10px;
    box-sizing: border-box;
    width: 50%;

    @include mq($from: 450px) {
      width: 33%;
    }

    @include mq($from: 600px) {
      width: 25%;
    }

    @include mq($from: 880px) {
      width: 20%;
    }

    @include mq($from: desktop) {
      padding: 0 20px;
      margin: 0;
      width: 20%;
    }
  }

  &__tooltip {
    display: none;

    &_visible {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      max-width: 100%;
      width: 150px;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(147, 188, 228, .4);
      position: absolute;
      margin-top: 9px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 9px 6px;
        border-color: transparent transparent #fff transparent;
        transform: translate(-50%, -100%);

        @include mq($from: desktop) {
          display: none;
        }
      }

      @include mq($from: desktop) {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 7px 0 0;
        width: auto;
        box-shadow: none;
        border-radius: 0;
        position: relative;
        background: none;
      }
    }
  }

  &__link {
    display: flex;
    position: relative;
    padding-left: 16px;
    font-size: 14px;
    line-height: 18px;
    color: $orange-color;
    text-decoration: none;
    min-width: 54px;
    box-sizing: border-box;

    &:before {
      content: '';
      width: 11px;
      height: 11px;
      position: absolute;
      top: 50%;
      left: 0;
      background: svg-load('text.svg', fill=$orange-color) center no-repeat;
      transform: translateY(-50%);
    }

    &_video:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 9px;
      border-color: transparent transparent transparent $orange-color;
    }

    &:hover {
      text-decoration: underline;
    }

    & + & {
      margin-top: 22px;

      @include mq($from: desktop) {
        margin: 0 0 0 21px;
      }
    }
  }
}
