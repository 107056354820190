.skolkovo {
  padding: 32px 0 80px;
  background: $light-blue-color;
  overflow: hidden;

  @include mq($from: desktop) {
    padding: 100px 0 140px;
    overflow: visible;
  }

  &__holder {
    @include holder;
  }

  &__list {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  &__column {
    margin-top: 50px;
    box-sizing: border-box;
    position: relative;
    width: 100%;

    @include mq($from: desktop) {
      padding: 0 50px 0 60px;
      width: 590px;
      flex-shrink: 0;
    }

    @include mq($from: 1100px) {
      padding: 0 100px 0 110px;
      width: 590px;
    }
  }

  &__bg {
    display: none;

    &.sticky {
      top: 150px;
    }

    @include mq($from: desktop) {
      display: block;
      border-radius: 10px;
      width: calc(100% - 590px);
    }
  }

  &__title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;

    @include mq($from: desktop) {
      position: relative;
      left: -188px;
      width: 676px;
    }

    @include mq($from: 1100px) {
      left: -238px;
    }
  }

  &__slider {
    overflow: visible;
    margin-top: 27px;

    @include mq($from: desktop) {
      margin-top: 85px;
    }
  }

  &__images {
    @include mq($from: desktop) {
      display: block;
    }
  }

  &__image {
    display: block;
    border-radius: 10px;
    margin: 0 auto;
    height: 279px;
    width: auto;

    @include mq($from: desktop) {
      max-height: 337px;

      &:first-child {
        display: none;
      }

      & + & {
        margin-top: 40px;
      }
    }
  }
}
