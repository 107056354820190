.hero {
  $root: &;
  padding: 0 0 20px 0;

  @include mq($until: tablet) {
    padding: 20px 0 40px;
    display: none;
  }

  background: #25ADE9;

  @include mq($from: desktop) {
    padding: 0 0 20px;
    /* margin-bottom: -147px; */
  }

  &__holder {
    @include holder;
  }

  &__olimp {
    display: flex;
    align-items: center;
    text-decoration: none;
    flex-shrink: 0;
    padding: 0 28px 0 0;
    margin-right: 28px;
    position: relative;

    @include mq($from: 1140px) {
      padding: 0 28px 0 96px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 1px;
      height: 44px;
      background: rgba($almost-black-color, .15);
      border-radius: 6px;
      transform: translate(0, -50%);
    }

    &-img {
      display: none;
      position: absolute;
      top: -18px;
      left: -35px;

      @include mq($from: 1140px) {
        display: block;
      }
    }

    &-text {
      font-size: 15px;
      line-height: 21px;
      color: $orange-color;
    }

    #{$root}__text {
      font-size: 20px;
      line-height: 29px;
    }
  }

  &__online {
    &-img {
      display: none;
      position: relative;
      left: 0;
      bottom: 0;
      align-self: flex-end;

      @include mq($from: 1140px) {
        display: block;
      }
    }
  }

  &__topline {
    display: none;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 23px;

    @include mq($from: tablet) {
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
    }

    &_content {
      display: flex;
      padding: 20px;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;

      &_text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 414px;

        @include mq($until: desktop) {
          width: 400px;
        }

        p {
          margin: 0;

          font-size: 18px;
          line-height: 140%;
          vertical-align: middle;

          @include mq($until: desktop) {
            font-size: 16px;
          }
        }
      }

      &_buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 26px 60px;
        column-gap: 20px;
        row-gap: 10px;

        width: 470px;
        @include mq($until: desktop) {
          width: auto;
        }

        text-wrap-mode: nowrap;
        word-break: keep-all;
        -webkit-hyphens: none;
        hyphens: none;

        h4 {
          grid-column: 1 / 3;
          line-height: 140%;
        }
      }
    }
  }

  &__text {
    font-size: 18px;
    line-height: 27px;
    display: none;
    color: #fff;

    @include mq($from: tablet) {
      display: block;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      color: $almost-black-color;
    }

    @include mq($until: desktop) {
      font-size: 20px;
    }

    &_mobile {
      display: block;
      text-align: center;

      @include mq($from: desktop) {
        display: none;
      }
    }
  }

  &__content {
    display: none;
    @include mq($from: desktop) {
      /* display: flex; */
      justify-content: space-between;
      margin-top: 51px;
      position: relative;
    }
  }

  &__arrow {
    display: none;
    position: absolute;
    bottom: 178px;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 13px;
    background: svg-load('arrow.svg', fill=#036894) center no-repeat;
    opacity: .5;
    transition: opacity .2s;
    cursor: pointer;
    border: 0;
    outline: 0;

    @include mq($from: desktop) {
      display: block;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__descr {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
    color: #fff;
    margin-top: 40px;
    text-align: center;

    @include mq($from: desktop) {
      font-size: 50px;
      line-height: 72px;
      margin-top: 122px;
      text-align: left;
    }
  }

  &__keyword {
    text-decoration: none;
    color: inherit;

    @include mq($from: desktop) {
      line-height: 46px;
      display: inline-block;
      padding: 5px;
      background: $orange-color;
      border-radius: 6px;
      position: relative;
      transition: background .2s;

      &:hover {
        background: #ff8f00;
      }

      &:after {
        content: '';
        position: absolute;
        left: 5px;
        bottom: 5px;
        width: calc(100% - 10px);
        height: 2px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, .6) 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 6px 2px;
        background-repeat: repeat-x;
      }
    }
  }

  &__fox {
    display: none;

    @include mq($from: desktop) {
      width: 430px;
      height: 690px;
      background: svg-load('hero-fox.svg') center no-repeat;
      margin-left: 20px;
      flex-shrink: 0;
      display: block;
    }
  }

  &__button {
    display: none;
    flex-shrink: 0;

    @include mq($from: tablet) {
      display: block;
    }

    &_mobile {
      display: block;
      margin-top: 50px;

      @include mq($from: desktop) {
        display: none;
      }
    }
  }
}