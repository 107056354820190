.ages {
  $root: &;
  padding: 40px 0 50px;
  background: #fff;
  overflow: hidden;

  @include mq($from: desktop) {
    padding: 104px 0 150px;
  }

  &__holder {
    @include holder;
  }

  &__title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
  }

  &__text {
    font-size: 20px;
    line-height: 34px;
    margin-top: 8px;

    @include mq($from: desktop) {
      margin-top: 34px;
    }
  }

  &__button {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;

    @include mq($from: desktop) {
      width: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: -54px;
    position: relative;
    z-index: 1;

    @include mq($from: desktop) {
      margin: 0;
    }
  }

  &__card {
    padding: 34px 20px;
    background: #60B3FF;
    box-sizing: border-box;
    border-radius: 15px;
    color: #fff;
    position: relative;
    opacity: .4;
    transition: opacity .2s;
    height: auto;

    &:hover {
      opacity: .6;
    }

    &.swiper-slide-active {
      opacity: 1;
    }

    @include mq($from: desktop) {
      padding: 49px 80px 65px 400px;

      &.swiper-slide-active #{$root}__image {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      }
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 325px; // как изображения в карточках уроков

    @include mq($from: desktop) {
      position: absolute;
      max-height: none;
      left: 20px;
      bottom: 0;
      transition: transform .35s ease;
      transform: translate3d(0, 59px, 0) scale3d(.75, .75, 1);
      transform-style: preserve-3d;
      z-index: 1;
    }
  }

  &__slider {
    margin-top: 29px;
    overflow: visible;

    @include mq($from: desktop) {
      margin: 72px 0 0;
      max-width: 980px;
    }
  }

  &__arrows {
    display: flex;
    justify-content: flex-start;

    @include mq($from: desktop) {
      justify-content: flex-end;
    }
  }

  &__list {
    margin: 24px 0 0;

    @include mq($from: desktop) {
      margin: 45px 0 0;
    }
  }

  &__pagination {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__buttons {
    margin-bottom: 21px;
    order: -1;

    @include mq($from: desktop) {
      margin-top: 44px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      order: 0;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    opacity: .5;
    text-decoration: none;
    transition: opacity .2s;
    display: none;
    position: relative;
    padding-left: 21px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 2px;
      background: #fff;
      top: calc(50% - 1px);
      left: 0;
      transform: translate(0, -50%);
    }

    &:after {
      width: 2px;
      height: 10px;
      left: 4px;
    }

    &:hover,
    &:active {
      opacity: 1;
    }

    &_mobile {
      display: flex;
      align-self: center;
      margin-top: 20px;
      text-align: center;
    }

    @include mq($from: desktop) {
      margin-left: 40px;
      display: flex;

      &_mobile {
        display: none;
      }
    }
  }
}
